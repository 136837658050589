<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addOffer'})">العروض</dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/flash-sales'" paginationName="عروض"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: this.$t('main.name'), key: 'name', class: 'text-start' },
        { label: this.$t('offers.offerType'), key: 'type', type: 'translation', objectTranslation: 'offers.typeStatus', class: 'text-start' },
        { label: this.$t('offers.offerStart'), key: 'start_date', type: 'date', class: 'text-start' },
        { label: this.$t('offers.offerEnd'), key: 'end_date', type: 'date', class: 'text-start' },
        { label: this.$t('main.status.status'), key: 'status', type: 'status', objectTranslation: 'main.status', class: 'text-start' },
        { label: this.$t('main.created_at'), key: 'created_at', type: 'dateTime', class: 'text-start' }
      ]
    }
  }
}
</script>
